<template>
  <div style="overflow: auto; height: 70vh;">
    <div class="l-main__ctn">
      <h4 class="u-typography-color md-title md-layout-item">
        {{ categoryHeader }}
      </h4>
      <div class="c-input u-flex u-align-items-center">
        <md-checkbox
          v-model="categoryActive"
          :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
          @change="checkIfInputsHaveChanged"
        />
        Category Active
      </div>
      <md-field>
        <label>Category Label</label>
        <md-input
          v-model="label"
          name="metaTitle"
          type="text"
          class="md-input"
          autocomplete="off"
          :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
          @input="checkIfInputsHaveChanged"
        />
      </md-field>
      <h4 class="u-typography-color md-title md-layout-item">
        Category Details
      </h4>
      <div class="md-layout languages-wrapper">
        <generic-button
          v-for="language in languages"
          :key="language.code"
          :variation="language.code === selectedLanguage.code ? 'orange' : ''"
          @click="changeLanguage(language)"
        >
          {{ language.name }}
        </generic-button>
      </div>
      <md-field>
        <label>Category Name</label>
        <md-input
          v-model="categoryName[selectedLanguage.code]"
          name="metaTitle"
          type="text"
          class="md-input"
          autocomplete="off"
          :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
          @input="checkIfInputsHaveChanged"
        />
      </md-field>
      <md-field>
        <label>Category Description</label>
        <md-input
          v-model="categoryDescription[selectedLanguage.code]"
          name="metaTitle"
          type="text"
          class="md-input"
          autocomplete="off"
          :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
          @input="checkIfInputsHaveChanged"
        />
      </md-field>

      <image-with-preview-field
        v-if="renderFilePicker"
        :accept="['.svg']"
        :image-name="iconName"
        :image-path="iconPath"
        placeholder="Upload logo (*.svg) - Optional (max 10kb)"
        :disabled="!canWriteHelpWebsite(getSelectedWebsiteId)"
        @onChange="handleIconChange"
      />

      <template>
        <p
          v-if="error && error.messages"
          class="md-error"
        >
          {{ error.messages }}
        </p>
      </template>
    </div>
    <div class="u-flex-right">
      <div class="u-flex">
        <generic-button
          variation="grey"
          class="u-mr-md"
          @click="close"
        >
          Close
        </generic-button>
        <generic-button
          variation="red"
          :disabled="!isLabelValid || !canWriteHelpWebsite(getSelectedWebsiteId) || !inputsChanged"
          @click="handleCreateOrUpdate"
        >
          {{ updateOrCreateLabel }}
        </generic-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {seoRulesService} from '@/services/seo-rules'
import GenericButton from '@/shared/buttons/GenericButton'
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";
import {websiteService} from "@/services/websites";
import {languageService} from '@/services/language';
import {each, get, isArray, kebabCase} from 'lodash';
import {jsonParse} from '@/helpers/transformers';
import {helpService} from "@/services/help";
import ImageWithPreviewField from "@/shared/custom-form-fields/ImageWithPreviewField.vue";
import config from "../../../../../../../config/local";
import {historyLoggerMixin} from "@/mixins/historyLoggerMixin";
import {nextTick} from "vue";

export default {
  name: 'EditCreateCategoryPage',
  components: {
    ImageWithPreviewField,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin, historyLoggerMixin],

  props: {
    selectedCategory: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      inputsChanged: false,
      initialData: {},
      messages: {
        error: null,
        success: null,
      },
      label: '',
      languages: [],
      categoryName: {},
      categoryDescription: {},
      icon: null,
      iconName: '',
      iconPath: '',
      iconChanged: false,
      selectedLanguage: {},
      categoryActive: false,
      renderFilePicker: true,
    };
  },

  computed: {
    ...mapGetters('helpDomains', ['getSelectedDomainId', 'getSelectedWebsiteId']),
    ...mapGetters("response", ["success", "error"]),

    updateOrCreateLabel() {
      return !this.selectedCategory ? 'Create' : 'Update';
    },
    categoryHeader() {
      return !this.selectedCategory ? 'New Category' : 'Update Category';
    },
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
    isLabelValid() {
      return this.label.trim() !== '';
    },
  },

  async created() {
    this.updateHelpCategory = await this.historyLoggerDecorator(helpService.updateHelpCategory)

    await this.getAllLanguagesForWebsite();
    this.selectedCategory ?
      this.predefinedDataFromPage() :
      this.resetData();

    nextTick(() => this.inputsChanged = false)
  },

  destroyed() {
    this.close()
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError", "setGlobalError"]),
    async getAllLanguagesForWebsite() {
      await this.getWebsite(this.getSelectedWebsiteId)
    },

    async getSeoRule() {
      const { entityType, entityId } = this.seoRule;

      try {
        const singleSeoRuleData = await seoRulesService.getSingleSeoRule(this.website.id, this.language.id, entityType, entityId)
        this.singleSeoRule = singleSeoRuleData?.data
      } catch (err) {
        // If entity is not found we will be creating new one, no additional checks needed
      }
    },

    checkIfInputsHaveChanged() {
      this.inputsChanged = true;
    },

    async handleIconChange(icon) {
      this.iconChanged = true;
      this.icon = icon;

      if (!icon) {
        this.iconName = '';
        this.iconPath = '';

        this.renderFilePicker = false;

        await this.$nextTick();
        this.renderFilePicker = true;
      }

      this.checkIfInputsHaveChanged();
    },

    formatCategorySlugs() {
      const slugs = {};

      each(Object.keys(this.categoryName), categoryKey => {
        slugs[categoryKey] = kebabCase(this.categoryName[categoryKey])
      })

      return slugs;
    },

    async handleCreateOrUpdate() {
      const formData = new FormData();

      formData.append('label', this.label);
      formData.append('name', JSON.stringify(this.categoryName));
      formData.append('description', JSON.stringify(this.categoryDescription));
      formData.append('isActive', this.categoryActive ? 'true' : 'false');
      formData.append('categorySlug', JSON.stringify(this.formatCategorySlugs()))

      if (this.iconChanged) {
        if (this.icon) {
          formData.append('image', this.icon);
        } else {
          formData.append('imageDeleted', 'true');
        }
      }

      try {
        !this.selectedCategory ?
          await helpService.createHelpCategory(formData, this.getSelectedDomainId) :
          await this.updateHelpCategory(formData, this.getSelectedDomainId, this.selectedCategory.id);

        const successMessage = !this.selectedCategory ?
          'Category Created' :
          'Category Updated';

        this.handleSuccess({message: successMessage});
        this.$emit("onSuccessCreateUpdate");
      } catch (err) {
        this.setGlobalError(isArray(err.data.message) ? err.data.message[0] : err.data.message);
      }
    },

    close() {
      this.resetError();
      this.$emit("onBack");
    },

    async getWebsite(websiteId) {
      const response = await websiteService.getSingleWebsite(websiteId)
      await this.getAvailableLanguages(response.data);
    },

    async getAvailableLanguages(data) {
      const allLanguagesResponse = await languageService.getAllOnlineLanguages();
      const allOnlineLanguages = allLanguagesResponse.data.items;

      const additionalLanguageCodes = jsonParse(get(data, 'betshop.market.additionalLanguages')) || [];
      const defaultLanguageCode = get(data, 'betshop.market.defaultLanguage', 'en');

      const betshopLanguageCodes = [...new Set([defaultLanguageCode, ...additionalLanguageCodes])];

      this.languages = allOnlineLanguages.filter(onlineLanguage => betshopLanguageCodes.includes(onlineLanguage.code));
      this.selectedLanguage = this.languages[0];
      this.canShowForm = true;
    },

    changeLanguage(language) {
      this.selectedLanguage = language;
    },

    predefinedDataFromPage() {
      this.label = get(this.selectedCategory, 'label', '');
      this.categoryDescription = get(this.selectedCategory, 'description', {});
      this.categoryName = get(this.selectedCategory, 'name', {});
      this.categoryActive = get(this.selectedCategory, 'isActive', false);
      this.iconName = get(this.selectedCategory, 'imageName', '');

      const imagePath = get(this.selectedCategory, 'imagePath', '');
      this.iconPath = imagePath ? `${config.BASE_URL}${imagePath}` : '';
    },

    resetData() {
      this.label = '';
      this.categoryDescription = {};
      this.categoryName = {};
    }
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin-top: 20px;
  padding: 10px 0 20px;
  font-size: 16px;
  border-bottom: 1px solid black;
  display: block;
}

.md-checkbox {
  display: flex;
  align-items: center;
}

.u-mr-md {
  margin-right: $md !important;
}

.c-header__info {
  display: flex;
  flex-direction: column;

  .c-header__info-label {
    margin: 5px 0;
    color: $white;
    span {
      font-weight: 700;
    }
  }

  .c-header__info-label:nth-child(1) {
    margin-top: 15px;
  }
}

.u-flex-right {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.languages-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;

  button {
    min-width: auto;
    padding: 5px 10px;
  }
}
</style>
