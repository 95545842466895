<template>
  <div style="overflow: auto; height: 70vh;">
    <div class="l-main__ctn">
      <h4>{{ selectedDomain || 'New Domain' }}</h4>
      <md-field class="u-width-m u-mb-xs">
        <label>Select domain</label>
        <md-select
          id="customPage"
          v-model="selectedDomain"
          name="customPage"
          :disabled="domain && !isAdmin()"
          @md-selected="selectDomainName"
        >
          <md-option
            v-for="website in availableWebsites"
            :key="website.url"
            :value="website.url"
            :disabled="!isAdmin()"
          >
            {{ website.name }}
          </md-option>
        </md-select>
      </md-field>

      <template v-if="selectedDomain">
        <image-with-preview-field
          v-if="renderFilePicker"
          :accept="['.svg']"
          :image-name="logoName"
          :image-path="logoPath"
          :disabled="!isAdmin()"
          placeholder="Upload logo (*.svg) - Optional (max 10kb)"
          @onChange="handleLogoChange"
        />
        <md-field>
          <label for="label">Brand Name</label>
          <md-input
            v-model="brandName"
            name="brandName"
            type="text"
            class="md-input"
            autocomplete="off"
            :disabled="!isAdmin()"
            @input="checkIfInputsHaveChanged"
          />
        </md-field>
        <h4>Settings</h4>
        <h6>Select Available languages</h6>
        <div v-if="languages.length" class="checkbox-grid-wrapper">
          <div
            v-for="language in languages"
            :key="language.name"
            class="c-input c-input--icon checkbox-item"
          >
            <md-checkbox
              v-model="availableLanguages[language.code]"
              :disabled="language.code === defaultLanguageCode || !isAdmin()"
              @change="checkIfInputsHaveChanged"
            />
            {{ language.name }} {{ language.code === defaultLanguageCode ? '(default)' : '' }}
          </div>
        </div>
        <md-field v-if="languages.length" class="u-width-m u-mb-xs">
          <label>Choose Default Language</label>
          <md-select
            id="defaultLanguage"
            v-model="defaultLanguageCode"
            name="defaultLanguage"
            :disabled="!isAdmin()"
            @md-selected="handleDefaultLanguageSelected"
          >
            <md-option
              v-for="language in languages"
              :key="language.name"
              :value="language.code"
            >
              {{ language.name }}
            </md-option>
          </md-select>
        </md-field>
        <h4>SEO</h4>
        <div v-if="languages.length" class="md-layout languages-wrapper">
          <generic-button
            v-for="language in languages"
            :key="`domain-seo-language-${language.code}`"
            :variation="language.code === selectedSeoLanguage.code ? 'orange' : ''"
            @click="changeSeoLanguage(language)"
          >
            {{ language.name }}
          </generic-button>
        </div>

        <div v-if="selectedSeoLanguage" class="inputs-wrapper">
          <md-field class="u-width-m u-mb-xs">
            <label for="label">Meta Title</label>
            <md-input
              v-model="metaTitle[selectedSeoLanguage.code]"
              name="metaTitle"
              type="text"
              class="md-input"
              autocomplete="off"
              :disabled="!isAdmin()"
              @input="checkIfInputsHaveChanged"
            />
          </md-field>
          <md-field class="u-width-m u-mb-xs">
            <label for="label">Meta Title Prefix</label>
            <md-input
              v-model="metaTitlePrefix[selectedSeoLanguage.code]"
              name="metaTitlePrefix"
              type="text"
              class="md-input"
              autocomplete="off"
              :disabled="!isAdmin()"
              @input="checkIfInputsHaveChanged"
            />
          </md-field>
          <md-field class="u-width-m u-mb-xs">
            <label for="label">Meta Title Suffix</label>
            <md-input
              v-model="metaTitleSuffix[selectedSeoLanguage.code]"
              name="metaTitleSuffix"
              type="text"
              class="md-input"
              autocomplete="off"
              :disabled="!isAdmin()"
              @input="checkIfInputsHaveChanged"
            />
          </md-field>
        </div>
        <md-field>
          <label for="label">Meta Description</label>
          <md-input
            v-model="metaDescription[selectedSeoLanguage.code]"
            name="metaDescription"
            type="text"
            class="md-input"
            autocomplete="off"
            :disabled="!isAdmin()"
            @input="checkIfInputsHaveChanged"
          />
        </md-field>
        <md-field>
          <label for="label">Meta Keywords</label>
          <md-input
            v-model="metaKeywords[selectedSeoLanguage.code]"
            name="metaKeywords"
            type="text"
            class="md-input"
            autocomplete="off"
            :disabled="!isAdmin()"
            @input="checkIfInputsHaveChanged"
          />
        </md-field>

        <label class="field-label">Footer Content</label>
        <wysiwyg-component
          v-model="footerContent[selectedSeoLanguage.code]"
          :disabled="!isAdmin()"
          theme="dark"
          @input="checkIfInputsHaveChanged"
        />

        <h4>Links</h4>
        <div class="checkbox-grid-wrapper">
          <div
            v-for="link in helpLinks"
            :key="`link-${link.id}`"
            class="checkbox-item"
          >
            <md-checkbox
              v-model="selectedLinks[link.id]"
              :disabled="!isAdmin()"
              @change="checkIfInputsHaveChanged"
            />
            {{ link.label }}
          </div>
        </div>
        <h4>Scripts</h4>
        <md-field class="disabled-field">
          <label>Help Head Code</label>
          <md-textarea
            v-model="helpHeadCode"
            rows="10"
            :disabled="!isAdmin()"
            @input="checkIfInputsHaveChanged"
          />
        </md-field>
        <md-field class="disabled-field">
          <label>Help Body Code</label>
          <md-textarea
            v-model="helpBodyCode"
            rows="10"
            :disabled="!isAdmin()"
            @input="checkIfInputsHaveChanged"
          />
        </md-field>
        <md-field class="disabled-field">
          <label>Help Footer Code</label>
          <md-textarea
            v-model="helpFooterCode"
            rows="10"
            :disabled="!isAdmin()"
            @input="checkIfInputsHaveChanged"
          />
        </md-field>
      </template>
    </div>
    <div class="u-flex-right u-margin-right-20">
      <div class="u-flex">
        <generic-button
          variation="grey"
          class="u-mr-md"
          @click="handleClose"
        >
          Close
        </generic-button>
        <generic-button
          :disabled="!selectedDomain || !isAdmin() || !inputsChanged"
          variation="red"
          @click="handleSave"
        >
          Save
        </generic-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GenericButton from '@/shared/buttons/GenericButton'
import ImageWithPreviewField from '@/shared/custom-form-fields/ImageWithPreviewField';
import {languageService} from '@/services/language';
import {jsonParse} from '@/helpers/transformers';
import {get} from 'lodash';
import {websiteService} from "@/services/websites";
import {helpService} from "@/services/help";
import config from "/config/local";
import WysiwygComponent from "@/shared/wysiwyg-component/WysiwygComponent.vue";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {historyLoggerMixin} from "@/mixins/historyLoggerMixin";

export default {
  name: 'CreateEditSettings',
  components: {
    WysiwygComponent,
    GenericButton,
    ImageWithPreviewField,
  },

  mixins: [onlinePermissionsMixin, historyLoggerMixin],

  props: {
    domain: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    allWebsites: {
      type: Array,
      required: false,
      default: () => {
      }
    }
  },

  data() {
    return {
      inputsChanged: false,
      selectedDomain: '',
      brandName: '',
      logo: null,
      logoName: '',
      logoPath: '',
      logoChange: false,
      selectedWebsite: {},
      languages: [],
      selectedSeoLanguage: {},

      defaultLanguageCode: {},
      availableLanguages: {},

      metaTitle: {},
      metaTitlePrefix: {},
      metaTitleSuffix: {},
      metaDescription: {},
      metaKeywords: {},

      footerContent: {},

      helpHeadCode: '',
      helpBodyCode: '',
      helpFooterCode: '',

      selectedLinks: {},
      renderFilePicker: true,
    };
  },

  computed: {
    ...mapGetters('response', ['success', 'globalError']),
    ...mapGetters('helpLinks', ['helpLinks']),
    ...mapGetters('helpDomains', ['helpDomains', 'getSelectedDomainId']),

    availableWebsites() {
      const usedWebsiteIds = this.helpDomains ? this.helpDomains.map(domain => domain.websiteId) : []

      return this.allWebsites.filter(website => !usedWebsiteIds.includes(website.id) || website.url === this.selectedDomain);
    },
  },

  async created() {
    this.updateHelpDomain = await this.historyLoggerDecorator(helpService.updateHelpDomain);

    await this.handleCreation();

    setTimeout(() => this.inputsChanged = false, 200)
  },

  methods: {
    ...mapActions('response', ['handleSuccess', 'setGlobalError', 'resetError']),
    ...mapActions('helpLinks', ['getHelpLinks']),

    async handleCreation() {
      await this.getHelpLinks();

      if (this.domain) {
        this.selectedDomain = this.domain.domain.website.url;
      }
    },

    async selectDomainName(domainUrl) {
      this.resetForm();

      const selectedWebsite = this.allWebsites.find(website => website.url === domainUrl);
      if (!selectedWebsite) {
        return;
      }

      await this.getWebsite(selectedWebsite.id)

      this.checkIfInputsHaveChanged();
    },

    checkIfInputsHaveChanged() {
      this.inputsChanged = true;
    },

    async handleLogoChange(logo) {
      this.logoChange = true;
      this.logo = logo;

      if (!logo) {
        this.logoName = '';
        this.logoPath = '';

        this.renderFilePicker = false;

        await this.$nextTick();
        this.renderFilePicker = true;
      }

      this.checkIfInputsHaveChanged();
    },

    async handleEditForm(hasDomainChanged = false) {
      const domain = this.domain.domain;

      this.logoName = domain.imageName;
      this.logoPath = domain.imagePath ? `${config.BASE_URL}${domain.imagePath}` : '';
      this.metaTitle = domain.metaTitle;
      this.metaTitlePrefix = domain.metaTitlePrefix;
      this.metaTitleSuffix = domain.metaTitleSufix;
      this.metaDescription = domain.metaDescription;
      this.metaKeywords = domain.metaKeywords;
      this.footerContent = domain.footerContent;
      this.helpHeadCode = domain.helpHeadCode;
      this.helpBodyCode = domain.helpBodyCode;
      this.helpFooterCode = domain.helpFooterCode;
      this.brandName = domain.brandName;

      this.selectedLinks = domain.helpDomainLinks.reduce((acc, link) => {
        acc[link.id] = true
        return acc;
      }, {});

      if (!hasDomainChanged) {
        let defaultLanguageCode = '';
        this.availableLanguages = {};

        domain.helpDomainLanguages.forEach(languageData => {
          if (languageData.isDefault) {
            defaultLanguageCode = languageData.language?.code;
          }

          this.availableLanguages[languageData.language?.code] = true;
        })

        this.defaultLanguageCode = defaultLanguageCode || '';

        this.handleDefaultLanguageSelected();
      }
    },

    changeSeoLanguage(language) {
      this.selectedSeoLanguage = language
    },

    handleDefaultLanguageSelected() {
      this.availableLanguages = {...this.availableLanguages, [this.defaultLanguageCode]: true};
      this.checkIfInputsHaveChanged();
    },

    handleClose(success = false) {
      this.resetForm();
      this.resetError();
      this.$emit(success ? 'success' : 'close');
    },

    async handleSave() {
      const formData = new FormData();

      formData.append('websiteId', this.selectedWebsite.id);
      formData.append('metaDescription', JSON.stringify(this.metaDescription));
      formData.append('metaTitlePrefix', JSON.stringify(this.metaTitlePrefix));
      formData.append('metaTitleSufix', JSON.stringify(this.metaTitleSuffix));
      formData.append('metaKeywords', JSON.stringify(this.metaKeywords));
      formData.append('metaTitle', JSON.stringify(this.metaTitle));
      formData.append('footerContent', JSON.stringify(this.footerContent));
      formData.append('helpHeadCode', this.helpHeadCode);
      formData.append('helpBodyCode', this.helpBodyCode);
      formData.append('helpFooterCode', this.helpFooterCode);
      formData.append('defaultLanguage', this.defaultLanguageCode);
      formData.append('brandName', this.brandName || '');

      if (this.logoChange) {
        if (this.logo) {
          formData.append('image', this.logo);
        } else {
          formData.append('imageDeleted', 'true');
        }
      }

      const enabledLanguageCodes = Object.keys(this.availableLanguages).filter(languageKey => this.availableLanguages[languageKey]);
      enabledLanguageCodes.forEach((enabledLanguageCode, i) => {
        formData.append(`languages[${i}]`, enabledLanguageCode)
      })

      const selectedLinkIds = Object.keys(this.selectedLinks).filter(selectedLinkId => this.selectedLinks[selectedLinkId]);
      selectedLinkIds.forEach((selectedLinkId, i) => {
        formData.append(`links[${i}]`, selectedLinkId)
      })

      try {
        if (this.domain) {
          formData.append('id', this.domain.domain.id);

          await this.updateHelpDomain(this.domain.domain.id, formData);
          this.handleSuccess({message: 'Domain Updated'});
          this.handleClose(true);

          return;
        }
        await helpService.createHelpDomain(formData);
        this.handleSuccess({message: 'Domain Created'});
        this.handleClose(true);
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    resetForm() {
      this.imageName = '';
      this.languages = [];
      this.selectedSeoLanguage = {};
      this.defaultLanguageCode = {};
      this.availableLanguages = {};
      this.metaTitle = {};
      this.metaTitlePrefix = {};
      this.metaTitleSuffix = {};
      this.metaDescription = {};
      this.metaKeywords = {};
      this.footerContent = {};
      this.helpHeadCode = '';
      this.helpBodyCode = '';
      this.helpFooterCode = '';
      this.selectedLinks = {};
      this.brandName = '';
    },

    async getWebsite(websiteId) {
      const response = await websiteService.getSingleWebsite(websiteId)
      this.selectedWebsite = response.data;
      this.selectedDomain = this.selectedWebsite.url;

      await this.getAvailableLanguages();
    },

    async getAvailableLanguages() {
      const allLanguagesResponse = await languageService.getAllOnlineLanguages();
      const allOnlineLanguages = allLanguagesResponse.data.items;

      const additionalLanguageCodes = jsonParse(get(this, 'selectedWebsite.betshop.market.additionalLanguages')) || [];
      const defaultLanguageCode = get(this, 'selectedWebsite.betshop.market.defaultLanguage', 'en');

      const betshopLanguageCodes = [...new Set([defaultLanguageCode, ...additionalLanguageCodes])];

      this.languages = allOnlineLanguages.filter(onlineLanguage => betshopLanguageCodes.includes(onlineLanguage?.code))
      this.selectedSeoLanguage = this.languages[0];

      this.defaultLanguageCode = defaultLanguageCode;

      this.availableLanguages = {...this.availableLanguages, [defaultLanguageCode]: true};

      if (this.domain) {
        const hasDomainChanged = this.domain.domain.website.url !== this.selectedDomain
        await this.handleEditForm(hasDomainChanged);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.u-mr-md {
  margin-right: $md !important;
}

.u-mb-md {
  margin-bottom: $md !important;
}

.inputs-wrapper {
  display: flex;
  gap: 40px;
  margin-top: 20px;
  margin-bottom: 20px;

  div {
    width: 100%;
    margin-bottom: 20px;
  }

  textarea {
    width: 100%;
  }
}

label {
  display: block;
}

h4 {
  margin-top: 20px;
  padding: 10px 0 20px;
  font-size: 16px;
  border-bottom: 1px solid black;
}

.checkbox-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  padding: 0 10px;
  margin-bottom: 20px;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.languages-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;

  button {
    min-width: auto;
    padding: 5px 10px;
  }
}

.disabled-field {
  pointer-events: none;
  opacity: 0.3;
}
</style>
