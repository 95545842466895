<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="u-typography-color md-title">
        Add new ticket banner
      </h4>
      <generic-button
        icon
        transparent
        @click="close()"
      >
        <md-icon> clear</md-icon>
      </generic-button>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form novalidate>
        <md-field :class="getValidationClass($v.ticketBannerItem.default.bannerName)">
          <label for="name">Banner name</label>
          <md-input
            v-model="ticketBannerItem.default.bannerName"
            v-focus
            name="bannerName"
            type="text"
            class="md-input"
            autocomplete="off"
          />
          <span
            v-if="!$v.ticketBannerItem.default.bannerName.required"
            class="md-error"
          >Ticket banner name is
            required</span>
        </md-field>
        <error-response-messages :field-name="'bannerName'" />
        <md-field :class="getValidationClass($v.ticketBannerItem.default.bannerUrl)">
          <label>Banner url</label>
          <md-input
            v-model="ticketBannerItem.default.bannerUrl"
            name="bannerUrl"
            type="text"
            class="md-input"
            autocomplete="off"
          />
          <span
            v-if="!$v.ticketBannerItem.default.bannerUrl.required"
            class="md-error"
          >Ticket banner url is
            required</span>
        </md-field>
        <label
          for="name"
          class="u-margin-10"
        >Is internal url</label>
        <md-checkbox
          v-model="ticketBannerItem.default.isInternalUrl"
          class="md-checkbox u-margin-10"
        />
        <div class="c-form__betshop-avatar">
          <div class="c-avatar">
            <img
              v-if="ticketBannerUrl() || getTicketBannerImagePath['default']"
              class="c-avatar__img"
              :src="getTicketBannerImagePath['default'] ? getTicketBannerImagePath['default'] : ticketBannerUrl()"
              :disabled="!bannerToUpload['default']"
              alt="ticket-banner"
            >
            <input
              type="file"
              name="imagePath"
              class="c-avatar__input"
              accept="image/*"
              @change="handleTicketBannerChange($event.target.files)"
            >
          </div>
          <div class="c-form__banner-right">
            <md-field :class="getValidationClass($v.ticketBannerItem.default.width)">
              <label>Width</label>
              <md-input
                v-model="ticketBannerItem.default.width"
                name="width"
                type="number"
                min="1"
                class="md-input"
                autocomplete="off"
                :disabled="!bannerToUpload['default']"
              />
              <span class="md-helper-text">Image width in PX</span>
              <span
                v-if="$v.ticketBannerItem.default.width.$invalid"
                class="md-error"
              >
                Width is required and must be a number greater than 0
              </span>
            </md-field>
            <md-field :class="getValidationClass($v.ticketBannerItem.default.height)">
              <label>Height in PX</label>
              <md-input
                v-model="ticketBannerItem.default.height"
                name="height"
                type="number"
                min="1"
                class="md-input"
                autocomplete="off"
                :disabled="!bannerToUpload['default']"
              />
              <span class="md-helper-text">Image height in PX</span>
              <span
                v-if="$v.ticketBannerItem.default.height.$invalid"
                class="md-error"
              >
                Height is required and must be a number greater than 0
              </span>
            </md-field>
            <md-field :class="getValidationClass($v.ticketBannerItem.default.quality)">
              <label>Quality</label>
              <md-input
                v-model="ticketBannerItem.default.quality"
                name="quality"
                type="number"
                min="1"
                max="100"
                class="md-input"
                autocomplete="off"
                :disabled="!bannerToUpload['default']"
              />
              <span class="md-helper-text">Image compression quality in percentages</span>
              <span
                v-if="$v.ticketBannerItem.default.quality.$invalid"
                class="md-error"
              >
                Quality is required and must be a number between 1 and 100
              </span>
            </md-field>
          </div>
        </div>
        <md-tabs
          class="c-tabs c-tabs--small"
          :md-active-tab="currentLanguage"
        >
          <md-tab
            v-for="language in languages"
            :id="language"
            :key="language"
            :md-label="language"
            @click="changeLanguage(language)"
          />
        </md-tabs>
        <div
          v-for="language in languages"
          :key="language"
        >
          <div v-if="language === currentLanguage">
            <md-field :class="getValidationClass($v.ticketBannerItem[language].bannerUrl)">
              <label>Banner url</label>
              <md-input
                v-model="ticketBannerItem[language].bannerUrl"
                name="bannerUrl"
                type="text"
                class="md-input"
                autocomplete="off"
              />
            </md-field>
            <label
              for="name"
              class="u-margin-10"
            >Is internal url</label>
            <md-checkbox
              v-model="ticketBannerItem[language].isInternalUrl"
              class="md-checkbox u-margin-10"
            />
            <div class="c-form__betshop-avatar">
              <div :class="['c-avatar']">
                <img
                  v-if="ticketBannerUrl(language) || getTicketBannerImagePath[language]"
                  class="c-avatar__img"
                  :src="getTicketBannerImagePath[language] ? getTicketBannerImagePath[language] : ticketBannerUrl(language)"
                  :disabled="!bannerToUpload[language]"
                  alt="ticket-banner"
                >
                <input
                  ref="file"
                  type="file"
                  name="imagePath"
                  class="c-avatar__input"
                  accept="image/*"
                  @change="handleTicketBannerChange($event.target.files, language)"
                >
                <div>
                  <generic-button
                    v-if="ticketBannerUrl(language)"
                    class="c-avatar__button"
                    variation="red"
                    @click="handleTicketBannerDelete(language)"
                  >
                    Delete
                  </generic-button>
                </div>
              </div>
              <div class="c-form__banner-right">
                <md-field :class="getValidationClass($v.ticketBannerItem[language].width)">
                  <label>Width</label>
                  <md-input
                    v-model="ticketBannerItem[language].width"
                    name="width"
                    type="number"
                    min="1"
                    class="md-input"
                    autocomplete="off"
                    :disabled="!bannerToUpload[language]"
                  />
                  <span class="md-helper-text">Image width in PX</span>
                  <span
                    v-if="$v.ticketBannerItem[language].width.$invalid"
                    class="md-error"
                  >
                    Width is required and must be a number greater than 0
                  </span>
                </md-field>
                <md-field :class="getValidationClass($v.ticketBannerItem[language].height)">
                  <label>Height in PX</label>
                  <md-input
                    v-model="ticketBannerItem[language].height"
                    name="height"
                    type="number"
                    min="1"
                    class="md-input"
                    autocomplete="off"
                    :disabled="!bannerToUpload[language]"
                  />
                  <span class="md-helper-text">Image height in PX</span>
                  <span
                    v-if="$v.ticketBannerItem[language].height.$invalid"
                    class="md-error"
                  >
                    Width is required and must be a number greater than 0
                  </span>
                </md-field>
                <md-field :class="getValidationClass($v.ticketBannerItem[language].quality)">
                  <label>Quality</label>
                  <md-input
                    v-model="ticketBannerItem[language].quality"
                    name="quality"
                    type="number"
                    min="1"
                    max="100"
                    class="md-input"
                    autocomplete="off"
                    :disabled="!bannerToUpload[language]"
                  />
                  <span class="md-helper-text">Image compression quality in percentages</span>
                  <span
                    v-if="$v.ticketBannerItem[language].quality.$invalid"
                    class="md-error"
                  >
                    Quality is required and must be a number between 1 and 100
                  </span>
                </md-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateForm"
      >
        Submit
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {required, numeric, minValue, between} from "vuelidate/lib/validators";
import GenericButton from "@/shared/buttons/GenericButton";
import ErrorResponseMessages from "@/shared/error-response-messages/ErrorResponseMessages";
import BaseModalComponent from "@/shared/modal/BaseModalComponent";
import {validationBaseMixin} from "@/mixins/ValidationBaseMixin";
import {logoFileExtensions} from "../../../../const/index";
import {checkFileExtensions} from "../../../../const/validator";
import {configurationsService} from "../../../../services/configurations";
import config from "../../../../../config/local";
import {responseService} from "../../../../services/response";
import get from "lodash/get";
import forEach from "lodash/forEach";
import map from "lodash/map";
import isArray from "lodash/isArray";

export default {
  name: "CreateTicketBannerItemModal",
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent,
  },
  mixins: [validationBaseMixin],
  props: {
    ticketBannerItem: {
      type: Object,
      required: true,
    },
    successFunction: {
      type: Function,
      required: true,
    },
    languages: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      messages: {
        error: null,
        success: null,
      },
      bannerFileError: null,
      bannerUploadFileExtensions: logoFileExtensions,
      previewTicketBannerUrl: this.languagesForBannerUrls(),
      bannerToUpload: this.languagesForBannerUrls(),
      currentLanguage: this.languages[0],
      formIsInvalid: false
    };
  },

  validations() {
    const createValidations = (langCode) => {
      const hasImageChange = this.bannerToUpload[langCode]

      return hasImageChange ?
        {
          bannerName: langCode === 'default' ? { required } : {},
          bannerUrl: { required },
          width: { numeric, required, minValue: minValue(1) },
          height: { numeric, required, minValue: minValue(1) },
          quality: { numeric, required, between: between(1, 100) },
        } :
        {
          bannerName: {},
          bannerUrl: {},
          width: {},
          height: {},
          quality: {},
        }
    };

    const validations = {};

    validations['default'] = createValidations('default');
    for (const langKey in this.languages) {
      const langCode = this.languages[langKey]
      validations[langCode] = createValidations(langCode);
    }

    return {
      ticketBannerItem: validations,
    };
  },

  computed: {
    ...mapGetters("response", ["success", "error"]),

    getTicketBannerImagePath() {
      return this.previewTicketBannerUrl;
    },
  },

  methods: {
    ...mapActions("response", [
      "handleSuccess",
      "handleError",
      "resetError",
      "setGlobalError",
    ]),

    ticketBannerUrl(lang = 'default') {
      const imageUrl = get(this.ticketBannerItem[lang], "imagePath", '');
      return imageUrl && `${config.BASE_URL}/${imageUrl}`;
    },

    async validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid || this.isInvalidImageForm()) {
        return
      }
      try {
        await this.saveBannerImages();
        this.successFunction(this.ticketBannerItem);
        this.close();
      } catch (error) {
        let formattedError = error.data.message

        if (isArray(error.data.message)) {
          formattedError = error.data.message.reduce((acc, message) => {
            return `${acc}${message}\n\r`
          }, '')
        }

        this.setGlobalError(formattedError);
      }
    },

    isInvalidImageForm() {
      this.formIsInvalid = false;
      const defaultIsValid = !!(this.bannerToUpload['default'] || this.ticketBannerUrl('default'));

      if (!defaultIsValid) {
        this.setGlobalError("Default Image is required.");
        this.formIsInvalid = true;
        return this.formIsInvalid
      }
    },

    close() {
      this.$emit("close");
      this.resetError();
    },

    handleTicketBannerChange(files, language = 'default') {
      this.validateImageFile(files.item(0));
      if (this.imageFileError) {
        this.setGlobalError("Only image files are allowed. (jpg, jpeg, png and gif)");
        return;
      }
      this.bannerToUpload[language] = files.item(0);
      this.$refs.file[0].value = '';
      const reader = new FileReader();
      reader.readAsDataURL(this.bannerToUpload[language]);
      reader.onload = (event) => {
        this.previewTicketBannerUrl[language] = event.target.result;
        this.resetValidations();
      };

      this.resetValidations();
    },

    async handleTicketBannerDelete(language) {
      try {
        const imageName = this.ticketBannerItem[language].imagePath.split('/').pop();
        await configurationsService
          .deleteLanguageTicketBanner(imageName)
          .then(() => {
            this.ticketBannerItem[language].imagePath = '';
            this.previewTicketBannerUrl[language] = '';
            this.successFunction(this.ticketBannerItem);
          });
        this.handleSuccess({message: `Ticket banner for ${language} deleted successfully.`});
      } catch (error) {
        this.handleError(responseService.getErrorMessage(error.data.message));
      }

    },

    validateImageFile(value) {
      this.imageFileError = checkFileExtensions(
        value,
        this.bannerUploadFileExtensions
      );
    },

    languagesForBannerUrls() {
      let previewBannerUrls = {default: ''};
      forEach(this.languages, lang => previewBannerUrls[lang] = '')

      return previewBannerUrls;
    },

    async saveBannerImages() {
      return Promise.all(map(Object.keys(this.bannerToUpload), (banner) => {
        if (this.bannerToUpload[banner]) {
          return this.handleTicketBannerUpdate(banner)
        }

        return Promise.resolve(true);
      }));
    },

    async handleTicketBannerUpdate(language = 'default') {
      const formData = new FormData();
      formData.append("file", this.bannerToUpload[language], this.bannerToUpload[language].name);
      formData.append("width", +this.ticketBannerItem[language].width);
      formData.append("height", +this.ticketBannerItem[language].height);
      formData.append("quality", +this.ticketBannerItem[language].quality);

      this.resetError();
      await configurationsService
        .saveTicketBannerImage(formData)
        .then((res) => {
          this.ticketBannerItem[language].imagePath = res.data.image.imagePath
        });
      this.handleSuccess({message: "Ticket banner uploaded successfully."});
      this.bannerToUpload[language] = null;
      this.resetValidations();
    },

    changeLanguage(language) {
      this.currentLanguage = language;
    },

    resetValidations() {
      this.$v.$reset();
    }
  },
};
</script>

<style lang="scss" scoped>
.c-avatar {
  min-height: 21rem;
}
</style>
